import React, { useContext, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, redirect, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { dettConfig } from "../../axiosConfig";
import { Context } from "../Contexts/Store";
import Buttons from "../general/Buttons";
import $ from "jquery";
import ButtonLoader from "../general/loaders/ButtonLoader";

export default function SignUpPage() {
  const [dropDown, setDropDown] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [readyToken, setReadyToken] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const [countryList, setCountryList] = useState([]);
  const [flag, setFlag] = useState(
    "https://developers-dett-prebooking.talrop.works/media/countries/flags/India.png"
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryCode, setCountryCode] = useState("IN");
  const [searchCountry, setSearchCountry] = useState("");
  const [phoneNumberLength, setPhoneNumberLength] = useState(10);

  //dropdown function
  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  useEffect(() => {
    phoneNumber && flag && setDropDown(false);
  }, [phoneNumber, flag]);

  //while going back we need to preserve phonecode,phone,flag,countrycode,
  useEffect(() => {
    user_data.phone_code
      ? setPhoneCode(user_data.phone_code)
      : setPhoneCode("+91");
    user_data.flag
      ? setFlag(user_data.flag)
      : setFlag(
          "https://developers-dett-prebooking.talrop.works/media/countries/flags/India.png"
        );
    user_data.phone ? setPhoneNumber(user_data.phone) : setPhoneNumber("");
    user_data.country_code
      ? setCountryCode(user_data.country_code)
      : setCountryCode("IN");
    user_data.phone_number_length
      ? setPhoneNumberLength(user_data.phone_number_length)
      : setPhoneNumberLength(10);
    setErrorMsg("");
  }, []);

  ///phone validation
  const phoneNumberValidation = (e) => {
    if (phoneNumber?.length > phoneNumberLength) {
      setErrorMsg(
        "You must enter" + " " + phoneNumberLength + " " + "digit number"
      );
    } else if (phoneNumber?.length < phoneNumberLength) {
      setErrorMsg("Enter a valid number");
    }
    setTimeout(() => {
      setErrorMsg("");
    }, 3000);
  };
  $(document).ready(function () {
    if (phoneNumber?.length == phoneNumberLength) {
      setReadyToken(true);
    } else if (phoneNumber?.length > phoneNumberLength) {
      setReadyToken(false);
    }
  });

  //outside click
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  //enter press function call
  const onKeyPressHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      phoneNumber && handleOtpVerification();
    }
  };

  //arrow key scroll preventing
  $(document).ready(function () {
    $("input[type=number]").on("focus", function () {
      $(this).on("keydown", function (event) {
        if (event.keyCode === 38 || event.keyCode === 40) {
          event.preventDefault();
        }
      });
    });
  });

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  // -----------------------------login verification-----------------
  const handleOtpVerification = async (e) => {
    setLoading(true);
    const token = await recaptchaRef.current.executeAsync();
    // readyToken &&
    dettConfig
      .post("api/v1/accounts/join/", {
        phone: phoneNumber,
        country: countryCode,
        "g-recaptcha-response": token,
      })
      .then((response) => {
        const { StatusCode, data } = response.data;
        if (StatusCode === 6000) {
          setLoading(false);
          setReadyToken(false);

          dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
              phone: phoneNumber,
              phone_code: phoneCode,
              flag: flag,
              phone_number_length: phoneNumberLength,
              country_code: countryCode,
            },
          });
          navigate("/otp");
        } else if (StatusCode === 6001) {
          setLoading(false);
          setReadyToken(false);
          setErrorMsg(data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  //to make input search empty
  useEffect(() => {
    setPhoneNumber("");
  }, [flag]);

  //countries list api
  useEffect(() => {
    dettConfig
      .get(`api/v1/general/countries/?q=${searchCountry}`)
      .then((response) => {
        const { data } = response.data.data;
        setCountryList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchCountry]);

  return (
    <MainContainer className="mobile">
      <SubContainer className="wrapper">
        <Container>
          <LeftContainer>
            <Paragraph>
              Wrap your <Span>love</Span> for your <Span>loved ones</Span>
            </Paragraph>
            <H6>Login with your phone number</H6>

            <LoginSetting ref={wrapperRef}>
              <CountryIcon>
                <Icon src={flag} />
              </CountryIcon>

              <ArrowIcon onClick={() => handleDropDown()}>
                <Arrow src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/ArrowDown2.png" />
              </ArrowIcon>
              <H3>{phoneCode}</H3>
              <FormTag>
                <Input
                  type="number"
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    isLoading && evt.preventDefault();
                  }}
                  onKeyPress={(e) => onKeyPressHandler(e)}
                  id="phone"
                  min="0"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  placeholder="Enter phone number"
                />
              </FormTag>

              <DropDown type={dropDown}>
                <SearchForm>
                  <SearchInput
                    type="search"
                    placeholder="Search Country"
                    onChange={(e) => setSearchCountry(e.target.value)}
                    value={searchCountry}
                  />
                </SearchForm>

                <Cover>
                  {dropDown && (
                    <CountrySetting>
                      {countryList.map((item) => (
                        <CountryDetails
                          onClick={() => {
                            setFlag(item.flag);
                            setPhoneCode(item.phone_code);
                            setPhoneNumberLength(item.phone_number_length);
                            setCountryCode(item.web_code);
                            setSearchCountry("");
                            setDropDown(!dropDown);
                          }}
                        >
                          <CountryImage>
                            <Country src={item.flag} />
                          </CountryImage>
                          <H2>{item.name}</H2>
                          <H5>{item.phone_code}</H5>
                        </CountryDetails>
                      ))}
                    </CountrySetting>
                  )}
                </Cover>
              </DropDown>
            </LoginSetting>
            <Error>{errorMsg}</Error>

            <OptTag>
              <OtpButton
                onClick={(e) => {
                  readyToken && handleOtpVerification(e);
                  phoneNumberValidation();
                }}
                // className={phoneNumber && "active"}
                type={readyToken}
              >
                {isLoading ? <ButtonLoader /> : "Send OTP"}
              </OtpButton>
            </OptTag>

            <TermsPrivacy>
              <LeftPara>By continuing, you agree to Dett's</LeftPara>
              <Policy>
                <C href="https://www.dett.app/terms/" target="_blank">
                  Terms & Conditions
                </C>{" "}
                &{" "}
                <D href="https://www.dett.app/privacy/" target="_blank">
                  Privacy Policy
                </D>
              </Policy>
            </TermsPrivacy>

            <ReCAPTCHA
              ref={recaptchaRef}
              //This ref can be used to call captcha related functions in case you need.
              sitekey="6LfzYwMiAAAAAIcd7i85zbUwcTayXgkvYEpLAQMt"
              size="invisible"
              badge="bottomleft"
            />
          </LeftContainer>

          <RightContainer>
            <PolicyDetails>
              <GifImage>
                <Image src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/SigninPageImg.png" />
              </GifImage>
              <Para>By continuing, you agree to Dett's</Para>
              <PolicyParagraph>
                <A href="https://www.dett.app/terms/" target="_blank">
                  Terms & Conditions
                </A>{" "}
                &{" "}
                <B href="https://www.dett.app/privacy/" target="_blank">
                  Privacy Policy
                </B>
              </PolicyParagraph>
            </PolicyDetails>
          </RightContainer>
        </Container>
      </SubContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/bgbar.png")
    no-repeat;
  background-size: contain;
  background-position: left top 15px;

  @media all and (max-width: 1280px) {
    background-position: left -75px top 15px;
    background-size: 48%;
  }

  @media all and (max-width: 1080px) {
    background-position: left -178px top 30px;
    background-size: 65%;
  }

  @media all and (max-width: 980px) {
    background-position: left -150px top 15px;
  }

  @media all and (max-width: 768px) {
    background-position: left -140px top 25px;
    background-size: 65%;
  }

  @media all and (max-width: 640px) {
    background-position: left -140px top 1px;
    background-size: 68%;
  }

  @media all and (max-width: 480px) {
    background: unset;
  }
`;

const SubContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 70%;
  height: 63vh;
  padding: 50px 50px 50px 100px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 5px 1px 8px #dddd;

  @media all and (max-width: 1280px) {
    width: 65%;
  }

  @media all and (max-width: 1080px) {
    padding: 50px 35px 50px 62px;
  }

  @media all and (max-width: 980px) {
    height: 70vh;
    width: 75%;
    padding: 50px 40px 50px 50px;
  }

  @media all and (max-width: 640px) {
    height: 75vh;
    flex-wrap: wrap-reverse;
    padding: 0px 40px 25px 50px;
  }
  @media all and (max-width: 480px) {
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
  }
  @media all and (max-width: 360px) {
    height: 65%;
  }
`;

const LeftContainer = styled.div`
  width: 42%;
  text-align: center;

  @media all and (max-width: 640px) {
    width: 98%;
  }
`;
const Cover = styled.div`
  max-height: 150px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background: #747474;
  }
`;

const CountrySetting = styled.div``;

const Paragraph = styled.p`
  width: 70%;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  text-align: left;

  @media all and (max-width: 1280px) {
    width: 80%;
  }

  @media all and (max-width: 1080px) {
    width: 95%;
  }

  @media all and (max-width: 980px) {
    width: 100%;
    font-size: 14px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 5px;
    font-size: 14px;
  }

  @media all and (max-width: 480px) {
    width: 82%;
    font-size: 16px;
    letter-spacing: 1px;
  }
  @media all and (max-width: 360px) {
    width: 90%;
    font-size: 14px;
  }
`;

const Span = styled.span`
  color: #fc8b62;
  font-size: 16px;
  font-weight: 600;
  font-family: "Gordita-Regular";

  @media all and (max-width: 980px) {
    width: 100%;
    font-size: 14px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 8px;
    font-size: 14px;
  }
  @media all and (max-width: 480px) {
    font-size: 16px;
    letter-spacing: 1px;
  }
  @media all and (max-width: 360px) {
    width: 90%;
    font-size: 14px;
  }
`;

const H6 = styled.h6`
  font-size: 12px;
  font-family: "Gordita-Light";
  font-weight: 600;
  margin-bottom: 30px;
  text-align: left;
  color: #747474;

  @media all and (max-width: 640px) {
    margin-bottom: 15px;
  }
  @media all and (max-width: 480px) {
    font-size: 11px;
  }

  @media all and (max-width: 480px) {
    margin-bottom: 25px;
    font-size: 14px;
  }

  @media all and (max-width: 360px) {
    font-size: 12px;
  }
`;
const Error = styled.div`
  color: #e93a22;
  margin: 10px 0;
  text-align: right;
  height: 20px;
  font-family: "Gordita-Regular";
  font-size: 14px;

  @media all and (max-width: 980px) {
    font-size: 10px;
  }

  @media all and (max-width: 768px) {
    font-size: 10px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;

const LoginSetting = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cfcfcf;
  position: relative;

  @media all and (max-width: 768px) {
    width: 100%;
    display: -webkit-inline-box;
  }

  /* @media all and (max-width: 480px) {
    flex-wrap: nowrap;
  } */
`;

const CountryIcon = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  margin-right: 5px;
  margin-bottom: 10px;

  @media all and (max-width: 1280px) {
    height: 22px;
    margin-right: 7px;
    width: 26px;
  }
  @media all and (max-width: 1080px) {
    margin-right: 10px;
    width: 31px;
    height: 20px;
    margin-right: 6px;
  }

  @media all and (max-width: 980px) {
    width: 29px;
    height: 19px;
  }

  @media all and (max-width: 768px) {
    width: 20px;
    height: 19px;
  }
  @media all and (max-width: 480px) {
    margin-right: 8px;
    width: 24px;
    height: 23px;
  }
  @media all and (max-width: 360px) {
    margin-right: 4px;
    width: 19px;
    height: 19px;
    margin-bottom: 7px;
  }
`;

const Icon = styled.img`
  width: 100%;
  display: block;
  border-radius: 50%;
`;

const ArrowIcon = styled.div`
  width: 12px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;

  @media all and (max-width: 1080px) {
    margin-right: 5px;
  }

  @media all and (max-width: 1080px) {
    margin-right: 6px;
  }
  @media all and (max-width: 480px) {
    width: 14px;
    margin-right: 10px;
  }
  @media all and (max-width: 360px) {
    width: 12px;
  }
`;

const Arrow = styled.img`
  width: 100%;
  display: block;
`;

const H3 = styled.h3`
  font-size: 12px;
  font-family: "Gordita-Regular";
  font-weight: 600;
  margin-right: 6px;
  text-align: center;

  @media all and (max-width: 980px) {
    font-size: 11px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
  }
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    margin-right: 3px;
  }
`;

const FormTag = styled.form`
  width: 58%;
  /* margin-bottom: 5px; */
  display: flex;

  @media all and (max-width: 1280px) {
    width: 85%;
  }

  @media all and (max-width: 1080px) {
    width: 100%;
  }

  @media all and (max-width: 768px) {
    width: 65%;
  }

  /* @media all and (max-width: 640px) {
    width: 70%;
  } */
`;

const Input = styled.input`
  width: 100%;
  /* letter-spacing: 1px; */
  font-size: 12px;
  font-family: "Gordita-Light";
  font-weight: 600;
  text-align: start;

  @media all and (max-width: 1080px) {
    font-size: 12px;
  }

  @media all and (max-width: 980px) {
    font-size: 11px;
  }

  @media all and (max-width: 768px) {
    font-size: 12px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
  }

  @media all and (max-width: 480px) {
    font-size: 12px;
  }
`;

const DropDown = styled.div`
  padding: 18px 15px;
  width: 100%;
  transition: 0.5s ease;
  background: #fffcf7;
  position: absolute;
  top: 34px;
  opacity: ${({ type }) => (type ? "1" : "0")};
  z-index: ${({ type }) => (type ? "1" : "-1")};

  @media all and (max-width: 1160px) {
    top: 31px;
  }

  @media all and (max-width: 768px) {
    top: 32px;
  }

  @media all and (max-width: 640px) {
    top: 30px;
  }

  @media all and (max-width: 480px) {
    top: 34px;
  }

  @media all and (max-width: 360px) {
    top: 28px;
  }
`;

const SearchForm = styled.form`
  margin-bottom: 5px;
  border: 2px solid #f2efe9;
  padding: 10px;
  /* background-size: 17px;
  border-radius: 8px;
  background-position: center left 15px; */

  @media all and (max-width: 1280px) {
    background-size: 15px;
  }

  @media all and (max-width: 768px) {
    background-size: 12px;
    background-position: center left 7px;
  }

  @media all and (max-width: 640px) {
    background-size: 14px;
    background-position: center left 25px;
  }
`;

const SearchInput = styled.input`
  font-size: 14px;
  font-weight: 600;
  color: #000;
  font-family: "Gordita-Light";
  /* margin-left: 30px; */
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Search.svg")
    no-repeat;
  background-size: 16px;
  border-radius: 8px;
  background-position: center left 0px;
  width: 100%;
  padding-left: 25px;

  @media all and (max-width: 1160px) {
    /* margin-left: 30px; */
    font-size: 12px;
  }

  @media all and (max-width: 980px) {
    font-size: 12px;
  }

  @media all and (max-width: 768px) {
    font-size: 10px;
    /* margin-left: 16px; */
  }

  @media all and (max-width: 640px) {
    /* margin-left: 12px; */
    font-size: 11px;
  }
`;

const CountryDetails = styled.li`
  display: flex;
  :hover {
    background: #fff7ea;
  }
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

const CountryImage = styled.div`
  width: 24px;

  @media all and (max-width: 1160px) {
    width: 22px;
  }

  @media all and (max-width: 980px) {
    width: 22px;
    margin-right: 5px;
  }

  @media all and (max-width: 768px) {
    display: none;
  }

  @media all and (max-width: 640px) {
    display: block;
  }
`;

const Country = styled.img`
  display: block;
  width: 100%;
`;

const H2 = styled.h2`
  font-size: 14px;
  font-family: "Gordita-Regular";
  font-weight: 600;
  width: 90px;
  text-align: left;

  @media all and (max-width: 1160px) {
    font-size: 10px;
    width: 60px;
  }

  @media all and (max-width: 980px) {
    font-size: 12px;
    margin-right: 5px;
    text-align: center;
  }

  @media all and (max-width: 768px) {
    font-size: 11px;
    text-align: left;
  }
`;

const H5 = styled.h5`
  font-size: 14px;
  font-family: "Gordita-Regular";
  font-weight: 600;
  width: 45px;
  text-align: left;

  @media all and (max-width: 1160px) {
    font-size: 10px;
  }
  @media all and (max-width: 980px) {
    font-size: 10px;
  }

  @media all and (max-width: 768px) {
    font-size: 10px;
  }
`;

const OptTag = styled.div`
  text-align: left;
  margin-top: 70px;

  @media all and (max-width: 640px) {
    margin-top: 30px;
  }
`;

const TermsPrivacy = styled.div`
  display: none;
  @media all and (max-width: 640px) {
    display: block;
  }
`;

const LeftPara = styled.p`
  font-size: 13px;
  font-weight: 600;
  font-family: "Gordita-Light";
  text-align: center;
  letter-spacing: 1.1px;
  line-height: 2.5;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
    width: 90%;
    margin: 0 auto;
    line-height: 1.5;
  }

  @media all and (max-width: 768px) {
    width: 80%;
    line-height: 2;
    margin: 0 auto;
  }
  @media all and (max-width: 640px) {
    margin-top: 10px;
  }

  @media all and (max-width: 360px) {
    width: 90%;
  }
`;

const Policy = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  width: 70%;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const C = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const D = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const OtpButton = styled.div`
  width: 100%;
  height: 50px;
  display: inline-block;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  background: #fc8b62;
  /* cursor: ${({ type }) => (type ? "pointer" : "not-allowed")}; */
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gordita-Medium";
  text-align: center;

  @media all and (max-width: 980px) {
    padding: 15px 40px;
    font-size: 11px;
  }

  @media all and (max-width: 768px) {
    padding: 12px 15px;
    font-size: 11px;
  }
  @media all and (max-width: 480px) {
    border-radius: 25px;
    padding: 14px 15px;
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 12px;
  }
`;

const RightContainer = styled.div`
  width: 48%;

  @media all and (max-width: 640px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 75%;
  }

  @media all and (max-width: 360px) {
    width: 80%;
  }
`;

const PolicyDetails = styled.div``;

const GifImage = styled.div`
  width: 75%;
  margin-bottom: 50px;
  margin: 50px 40px;

  @media all and (max-width: 980px) {
    width: 90%;
    margin: 0px 0px 48px 4px;
  }

  @media all and (max-width: 768px) {
    width: 100%;
    margin: 0px 0px 48px 0px;
  }

  @media all and (max-width: 640px) {
    width: 70%;
    margin: 0px 0px 0px 50px;
  }

  @media all and (max-width: 360px) {
    margin: 0px 0px 30px 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  display: block;
`;

const Para = styled.p`
  font-size: 13px;
  font-weight: 600;
  font-family: "Gordita-Light";
  text-align: center;
  letter-spacing: 1.1px;
  line-height: 2.5;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
    width: 90%;
    margin: 0 auto;
    line-height: 1.5;
  }

  @media all and (max-width: 768px) {
    width: 90%;
    line-height: 2;
    margin: 0 auto;
  }
  @media all and (max-width: 640px) {
    display: none;
  }
`;

const PolicyParagraph = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  width: 70%;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }

  @media all and (max-width: 640px) {
    display: none;
  }
`;

const A = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const B = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

import React, { Suspense, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Context } from "../../Contexts/Store";
import Loader from "../../general/loaders/Loader";
import Accounts from "../../screens/Accounts";
import Gifting from "../../screens/Gifting";
import OccasionSuccess from "../../screens/OccasionSuccess";
import PageNotFound from "../../screens/PageNotFound";
import SuccessPage from "../../screens/SuccessPage";
import PrivateRoute from "../routes/PrivateRoute";

export default function AppRouter() {
  const {
    state: { user_details },
  } = useContext(Context);
  const name = user_details.name;
  const dob = user_details.dob;
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<Navigate to="gifting" />} />
        <Route
          path="/gifting"
          element={
            name || dob ? (
              <PrivateRoute>
                <Gifting />
              </PrivateRoute>
            ) : (
              <Navigate to="/accounts" />
            )
          }
        />
        <Route
          path="/accounts"
          element={
            <PrivateRoute>
              <Accounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/success"
          element={
            <PrivateRoute>
              <SuccessPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/occasion-success"
          element={
            <PrivateRoute>
              <OccasionSuccess />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
}

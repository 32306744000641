import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../general/loaders/Loader";
import { Context } from "../../Contexts/Store";
import AppRouter from "./AppRouter";
import AuthRouter from "./AuthRouter";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";

export default function MainRouter() {
  const [isLoading, setLoading] = useState(true);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const is_exists = user_data.is_exists;

  useEffect(() => {
    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);
    dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });

    let user_details = localStorage.getItem("user_details");
    user_details = JSON.parse(user_details);
    dispatch({ type: "UPDATE_USER_DETAILS", user_details: user_details });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Routes>
          <Route
            path="/*"
            element={
              is_exists ? (
                <AppRouter />
              ) : (
                <AuthRoute>
                  <AuthRouter />
                </AuthRoute>
              )
            }
          />
        </Routes>
      )}
    </>
  );
}

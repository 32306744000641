import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

function SuccessModal({ isSubmit }) {
  const clearData = () => {
    window.localStorage.clear();
  };
  return (
    <MainContainer>
      {isSubmit ? <Overlay></Overlay> : ""}
      <BackContainer style={{ transform: isSubmit && "scale(1,1)" }}>
        <Modal type="textarea">
          <Cross onClick={() => clearData()}>
            <a href="https://www.dett.app/">
              <img
                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/cross.svg"
                alt=""
              />
            </a>
          </Cross>
          <Container>
            <Content>
              <ImageContainer>
                <img
                  src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/submit.svg"
                  alt="image"
                />
              </ImageContainer>
              <Text>
                Your occasion added <Br />
                <span>successfully!</span>
              </Text>
              <a href="">
                <Button>Add new ocassion</Button>
              </a>
            </Content>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}

const Content = styled.div``;
const Button = styled.button`
  width: 100%;
  height: 44px;
  margin-top: 25px;
  background: #fc8c64;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Gordita-Regular";
  cursor: pointer;
`;

const Cross = styled.div`
  width: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  img {
    width: 100%;
    display: block;
    cursor: pointer;
  }
`;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  /* max-width: 650px; */
  margin: 0 auto;
  right: 0;
  /* height: 100vh; */
  z-index: 1000;
  left: 0;
  top: 50%;
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const MainContainer = styled.div``;
const Modal = styled.div`
  /* width: 90%;
    max-width: 490px; */
  position: relative;
  padding: 0px 80px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  background: #fff;
  @media all and (max-width: 1280px) {
    width: 500px;
    padding: 0px 20px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 480px;
    padding: 0px 20px;
  }
  @media all and (max-width: 768px) {
    width: 480px;
  }
  @media all and (max-width: 640px) {
    width: 400px;
  }
  @media all and (max-width: 480px) {
    width: 340px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 58vh;
  margin: 0 auto;
  border-radius: 8px;

  @media all and (max-width: 1280px) {
    width: 75%;
  }

  @media all and (max-width: 980px) {
    height: 60vh;
    padding: 0px;
  }
  @media all and (max-width: 640px) {
    height: 50vh;
    padding: 0px;
  }
  @media all and (max-width: 480px) {
    box-shadow: none;
    padding: 0px;
  }
`;
const ImageContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 980px) {
    width: 46%;
  }
`;
const Text = styled.h2`
  color: #4a4a4a;
  font-size: 18px;
  text-align: center;
  font-family: "Gordita-Medium";
  span {
    color: #fe9a76;
  }
  @media all and (max-width: 360px) {
    font-size: 14px;
  }
`;
const Br = styled.br`
  @media all and (max-width: 360px) {
    display: none;
  }
`;

export default SuccessModal;

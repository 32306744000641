import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import ReCAPTCHA from "react-google-recaptcha";
import { dettConfig } from "../../axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../Contexts/Store";
import ButtonLoader from "../general/loaders/ButtonLoader";

export default function OtpPage() {
  const [isError, setIsError] = useState(false);
  const [otpValue, setValue] = useState("");
  const [counter, setCounter] = useState(30);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //timer clock
  useEffect(() => {
    const countValue = () => {
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      }
    };
    countValue();
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        counter: counter,
      },
    });
  }, [counter]);

  useEffect(() => {
    user_data.counter ? setCounter(user_data.counter) : setCounter(counter);
  }, []);

  //enter press function call
  document.onkeyup = enter;
  function enter(e) {
    if (e.which == 13) otpVerification(e);
  }

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const handleChange = (value) => {
    setValue(value);
  };

  const phoneNumber = user_data?.phone;
  const phoneCode = user_data?.phone_code;

  const recaptchaRef = useRef(null);
  const recapcharesendRef = useRef(null);
  const navigate = useNavigate();

  //-----------------------------login verification-----------------
  const otpVerification = (e) => {
    setLoading(true);
    const token = recaptchaRef.current.executeAsync();
    dettConfig
      .post("api/v1/accounts/verify/otp/", {
        otp: otpValue,
        country: user_data?.country_code,
        phone: phoneNumber,
        "g-recaptcha-response": token,
        // counter: counter,
      })
      .then((response) => {
        const { StatusCode, data } = response.data;
        if (StatusCode === 6000) {
          dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
              access_token: data.access_token,
              is_exists: true,
            },
          });
          navigate("/accounts");
        } else if (StatusCode === 6001) {
          setErrorMessage(data.message);
          setLoading(false);
          setIsError(true);
          setTimeout(() => {
            setIsError(false);
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //resend otp verification
  const otpresend = async (e) => {
    setValue();
    const token = await recapcharesendRef.current.executeAsync();
    dettConfig
      .post("api/v1/accounts/resend/otp/", {
        country: user_data?.country_code,
        phone: phoneNumber,
        "g-recaptcha-response": token,
      })
      .then((response) => {
        const { StatusCode, data } = response.data;
        if (StatusCode === 6000) {
          setCounter(30);
          dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
              access_token: data.access_token,
            },
          });
        } else {
          setIsError(true);
          setErrorMessage(data.message);
          setTimeout(() => {
            setIsError(false);
            setErrorMessage("");
          }, 4500);
        }
      })
      .catch((error) => {});
  };

  return (
    <MainContainer className="mobile">
      <SubContainer className="wrapper">
        <Container>
          <LeftContainer>
            <H1>Verify</H1>
            <Paragraph>
              Please enter the 4 digit verification <Br />
              code that is send to you at <Bbr />
              <Span>
                {phoneCode} {phoneNumber}
              </Span>
            </Paragraph>

            <OtpSetting>
              <OtpInput
                value={otpValue}
                onChange={(value) => {
                  handleChange(value);
                }}
                shouldAutoFocus={true}
                numInputs={4}
                placeholder={"0000"}
                inputStyle={isError ? "error" : "OtpInput"}
                containerStyle={isError ? "errorstyle" : "OtpStyle"}
                isInputNum={true}
              />
            </OtpSetting>
            <Notifications>
              {isError ? (
                <Failure>{errorMessage}</Failure>
              ) : (
                <Failure></Failure>
              )}
              <OtpTag>
                {!errorMessage && counter === 0 ? (
                  <ReSend onClick={(e) => otpresend(e)}>
                    <ReCAPTCHA
                      ref={recapcharesendRef}
                      sitekey="6LfzYwMiAAAAAIcd7i85zbUwcTayXgkvYEpLAQMt"
                      size="invisible"
                      badge="bottomleft"
                    />{" "}
                    Resend
                  </ReSend>
                ) : (
                  !isError && (
                    <Otp>
                      <OtpMessage>Don't receive code?</OtpMessage>
                      <Num>{counter} sec</Num>
                    </Otp>
                  )
                )}
              </OtpTag>
            </Notifications>
            <OtpButton onClick={(e) => otpVerification(e)}>
              <ReCAPTCHA
                ref={recaptchaRef}
                //This ref can be used to call captcha related functions in case you need.
                sitekey="6LfzYwMiAAAAAIcd7i85zbUwcTayXgkvYEpLAQMt"
                size="invisible"
                badge="bottomleft"
                className="active"
              />
              {isLoading ? <ButtonLoader /> : "Verify"}
            </OtpButton>

            <TermsPrivacy>
              <LeftPara>By continuing, you agree to Dett's</LeftPara>
              <Policy>
                <C href="https://www.dett.app/terms/" target="_blank">
                  Terms & Conditions
                </C>{" "}
                &{" "}
                <D href="https://www.dett.app/privacy/" target="_blank">
                  Privacy Policy
                </D>
              </Policy>
            </TermsPrivacy>
          </LeftContainer>
          <RightContainer>
            <PolicyDetails>
              <GifImage>
                <Image src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/SigninPageImg.png" />
              </GifImage>
              <Para>By continuing, you agree to Dett's</Para>
              <PolicyParagraph>
                <A href="https://www.dett.app/terms/" target="_blank">
                  {" "}
                  Terms & Conditions
                </A>{" "}
                &{" "}
                <B href="https://www.dett.app/privacy/" target="_blank">
                  Privacy Policy
                </B>
              </PolicyParagraph>
            </PolicyDetails>
          </RightContainer>
        </Container>
      </SubContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/bgbar.png")
    no-repeat;
  background-size: contain;
  background-position: left top 15px;

  @media all and (max-width: 1280px) {
    background-position: left -75px top 15px;
    background-size: 48%;
  }

  @media all and (max-width: 1080px) {
    background-position: left -178px top 30px;
    background-size: 65%;
  }

  @media all and (max-width: 980px) {
    background-position: left -150px top 15px;
  }

  @media all and (max-width: 768px) {
    background-position: left -140px top 25px;
    background-size: 65%;
  }

  @media all and (max-width: 640px) {
    background-position: left -140px top 1px;
    background-size: 68%;
  }

  @media all and (max-width: 480px) {
    background: unset;
  }

  @media all and (max-width: 380px) {
    background-size: 80%;
    background-position: left -95px top 45px;
  }
`;

const SubContainer = styled.div``;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 70%;
  height: 63vh;
  padding: 50px 50px 50px 100px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 5px 1px 8px #dddd;

  @media all and (max-width: 1280px) {
    width: 68%;
  }

  @media all and (max-width: 1160px) {
    width: 72%;
  }

  @media all and (max-width: 1080px) {
    width: 72%;
    padding: 50px 35px 50px 62px;
  }

  @media all and (max-width: 980px) {
    height: 70vh;
    width: 83%;
    padding: 50px 40px 50px 50px;
  }

  @media all and (max-width: 640px) {
    height: 75vh;
    flex-wrap: wrap-reverse;
    padding: 0px 40px 25px 50px;
  }

  @media all and (max-width: 480px) {
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
  }
`;

const LeftContainer = styled.div`
  width: 42%;
  text-align: center;

  @media all and (max-width: 640px) {
    width: 95%;
  }
`;

const H1 = styled.p`
  width: 70%;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  text-align: left;

  @media all and (max-width: 980px) {
    font-size: 14px;
  }

  @media all and (max-width: 768px) {
    width: 90%;
    font-size: 15px;
  }

  @media all and (max-width: 640px) {
    font-size: 13px;
  }

  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;

const Paragraph = styled.p`
  font-size: 13px;
  font-family: "Gordita-Light";
  font-weight: 600;
  margin-bottom: 30px;
  text-align: left;
  color: #747474;

  @media all and (max-width: 980px) {
    font-size: 12px;
  }
  @media all and (max-width: 640px) {
    width: 90%;
    font-size: 13px;
    margin-bottom: 20px;
  }

  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

const Span = styled.span`
  color: #fc8b62;
  font-size: 13px;
  font-weight: 600;
  font-family: "Gordita-Regular";

  @media all and (max-width: 980px) {
    font-size: 12px;
  }
  @media all and (max-width: 640px) {
    font-size: 13px;
  }
  @media all and (max-width: 360px) {
    font-size: 11px;
  }
`;

const Br = styled.br`
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const Bbr = styled.br`
  @media all and (max-width: 640px) {
    display: none;
  }
`;

const OtpSetting = styled.div`
  display: flex;
  align-items: center;
`;

const OtpTag = styled.div`
  text-align: left;
`;

const OtpMessage = styled.span`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  text-align: right;
  color: #747474;
  letter-spacing: 0.5px;

  @media all and (max-width: 980px) {
    font-size: 10px;
    text-align: start;
  }
`;

const Num = styled.span`
  display: block;
  width: 55px;
  font-size: 12px;
  font-family: "Gordita-Light";
  color: #fc8b62;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-left: 5px;

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const Otp = styled.div`
  display: flex;
  width: 100%;
`;

const OtpButton = styled.a`
  width: 100%;
  display: inline-block;
  margin-top: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #fc8b62;
  /* padding: 15px 65px; */
  font-size: 14px;
  cursor: pointer;
  font-family: "Gordita-Medium";
  text-align: center;
  border-radius: 6px;

  @media all and (max-width: 1160px) {
    font-size: 12px;
    border-radius: 5px;
  }
  @media all and (max-width: 980px) {
    font-size: 11px;
  }

  @media all and (max-width: 768px) {
    font-size: 11px;
    height: 40px;
  }

  @media all and (max-width: 640px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  @media all and (max-width: 480px) {
    border-radius: 25px;
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 12px;
  }
`;

const TermsPrivacy = styled.div`
  display: none;
  @media all and (max-width: 640px) {
    display: block;
  }
`;

const LeftPara = styled.p`
  font-size: 13px;
  font-weight: 600;
  font-family: "Gordita-Light";
  text-align: center;
  letter-spacing: 1.1px;
  line-height: 2.5;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
    width: 90%;
    margin: 0 auto;
    line-height: 1.5;
  }

  @media all and (max-width: 768px) {
    width: 80%;
    line-height: 2;
    margin: 0 auto;
  }

  @media all and (max-width: 360px) {
    width: 90%;
  }
`;

const Policy = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  width: 70%;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const C = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const D = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const RightContainer = styled.div`
  width: 48%;

  @media all and (max-width: 640px) {
    width: 80%;
  }

  @media all and (max-width: 480px) {
    width: 75%;
  }

  @media all and (max-width: 360px) {
    width: 80%;
  }
`;

const PolicyDetails = styled.div``;

const GifImage = styled.div`
  width: 75%;
  margin-bottom: 50px;
  margin: 50px 40px;

  @media all and (max-width: 980px) {
    width: 90%;
    margin: 0px 0px 48px 4px;
  }

  @media all and (max-width: 768px) {
    width: 100%;
    margin: 0px 0px 48px 0px;
  }

  @media all and (max-width: 640px) {
    width: 65%;
    margin: 0px 0px 0px 50px;
  }

  @media all and (max-width: 480px) {
    width: 70%;
    margin: 0px 0px 0px 50px;
  }

  @media all and (max-width: 360px) {
    width: 80%;
    margin: 0px 0px 0px 35px;
  }
`;

const Image = styled.img`
  width: 100%;
  display: block;
`;

const Para = styled.p`
  font-size: 13px;
  font-weight: 600;
  font-family: "Gordita-Light";
  text-align: center;
  letter-spacing: 1.1px;
  line-height: 2.5;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
    width: 90%;
    margin: 0 auto;
    line-height: 1.5;
  }

  @media all and (max-width: 768px) {
    width: 80%;
    line-height: 2;
    margin: 0 auto;
  }
  @media all and (max-width: 640px) {
    display: none;
  }
`;

const PolicyParagraph = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  width: 70%;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }

  @media all and (max-width: 640px) {
    display: none;
  }
`;

const A = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 5px;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;

const B = styled.a`
  font-size: 12px;
  font-weight: 600;
  font-family: "Gordita-Light";
  color: #f28a61;
  border-bottom: 1px solid #f28a61;
  letter-spacing: 1px;
  cursor: pointer;
  display: inline-block;

  @media all and (max-width: 1080px) {
    font-size: 11px;
  }

  @media all and (max-width: 980px) {
    font-size: 10px;
  }
`;
const Failure = styled.span`
  color: red;
  font-size: 12px;
  text-align: left;
  font-family: "Gordita-Regular";
  margin-top: 15px;
  height: 35px;

  @media all and (max-width: 768px) {
    font-size: 11px;
  }
`;

const Notifications = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
`;

const ReSend = styled.span`
  color: red;
  cursor: pointer;
  font-size: 12px;
  font-family: "Gordita-Regular";

  @media all and (max-width: 980px) {
  }

  @media all and (max-width: 768px) {
    font-size: 11px;
  }
`;

import { BrowserRouter, Router } from "react-router-dom";
import "./assets/css/style.css";
import Store from "./components/Contexts/Store";
import MainRouter from "./components/routing/router/MainRouter";
import PageNotFound from "./components/screens/PageNotFound";

function App() {
    return (
        <Store>
            <BrowserRouter>
                <MainRouter />
            </BrowserRouter>
        </Store>
    
    );
}

export default App;

import React, { Children, useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Context } from "../../Contexts/Store";

const AuthRoute = ({ children }) => {
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const is_exists = user_data.is_exists;
  const phone = user_data.phone;

  const [searchParams] = useSearchParams();
  const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";

  return !is_exists ? children : <Navigate to={nextPath} />;
};

export default AuthRoute;

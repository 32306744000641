import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function LandingPage() {
  return (
    <MainContainer className="mobile">
      <SubContainer className="wrapper">
        <Container>
          <AllItems>
            <ItemContainer>
              <ImageContainer>
                <Image src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/LandingPageImg.png" />
              </ImageContainer>
            </ItemContainer>
            <ItemDetails>
              <Paragraph>
                <Span>Distance</Span> never matters, make them feel your
                presence
              </Paragraph>
              <H6>
                Pre-book your special days bookmark those <Bold>moments</Bold>
              </H6>
              <ButtonContainer to="/signin">Get started</ButtonContainer>
            </ItemDetails>
          </AllItems>
        </Container>
      </SubContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/bgbar.png")
    no-repeat;
  background-size: contain;
  background-position: left top 15px;

  @media all and (max-width: 1280px) {
    background-position: left -75px top 15px;
    background-size: 48%;
  }

  @media all and (max-width: 1080px) {
    background-position: left -178px top 30px;
    background-size: 65%;
  }

  @media all and (max-width: 980px) {
    background-position: left -150px top 15px;
  }

  @media all and (max-width: 768px) {
    background-position: left -140px top 25px;
    background-size: 65%;
  }

  @media all and (max-width: 640px) {
    background-position: left -115px top 38px;
    background-size: 68%;
  }

  @media all and (max-width: 480px) {
    background: unset;
  }
`;

const SubContainer = styled.div``;

const Container = styled.div`
  background: #f8f7f7;
  width: 70%;
  height: 63vh;
  padding: 50px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 5px 1px 8px #dddd;
  display: flex;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 1280px) {
    width: 68%;
  }

  @media all and (max-width: 1160px) {
    width: 72%;
  }

  @media all and (max-width: 1080px) {
    padding: 50px 35px 50px 62px;
    width: 72%;
  }

  @media all and (max-width: 980px) {
    height: 70vh;
    width: 83%;
    padding: 50px 0px 50px 10px;
  }

  @media all and (max-width: 768px) {
    padding: 40px 40px 25px 50px;
    width: 84%;
  }

  @media all and (max-width: 640px) {
    width: 83%;
  }

  @media all and (max-width: 480px) {
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
    background: #fff;
  }
`;

const AllItems = styled.div``;

const ItemContainer = styled.div``;

const ImageContainer = styled.div`
  width: 45%;
  margin: 0 auto;
  margin-bottom: 35px;

  @media all and (max-width: 1280px) {
    margin-bottom: 25px;
    width: 50%;
  }
  @media all and (max-width: 1080px) {
    width: 48%;
  }

  @media all and (max-width: 980px) {
    width: 45%;
  }

  @media all and (max-width: 980px) {
    width: 50%;
  }

  @media all and (max-width: 640px) {
    width: 60%;
    margin-left: 35px;
  }

  @media all and (max-width: 480px) {
    width: 65%;
  }
`;

const Image = styled.img`
  width: 100%;
  display: block;
`;

const ItemDetails = styled.div`
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  margin-bottom: 10px;

  @media all and (max-width: 1280px) {
    font-size: 14px;
    letter-spacing: 0.4px;
  }
  @media all and (max-width: 1080px) {
    font-size: 14px;
    margin-bottom: 0px;
  }

  @media all and (max-width: 980px) {
    margin: 10px auto;
  }

  @media all and (max-width: 768px) {
    font-size: 13px;
  }
`;

const Span = styled.span`
  color: #fc8b62;
  font-size: 18px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  @media all and (max-width: 1280px) {
    font-size: 14px;
    letter-spacing: 0.4px;
  }
  @media all and (max-width: 1080px) {
    font-size: 14px;
  }

  @media all and (max-width: 980px) {
    margin: 10px auto;
  }
  @media all and (max-width: 768px) {
    font-size: 13px;
  }
`;

const H6 = styled.h6`
  font-size: 14px;
  font-family: "Gordita-Light";
  font-weight: 600;
  margin-bottom: 30px;
  @media all and (max-width: 1280px) {
    font-size: 12px;
    letter-spacing: 0.2px;
  }

  @media all and (max-width: 1080px) {
    margin: 6px auto 25px;
  }

  @media all and (max-width: 980px) {
    margin: 10px auto 25px;
  }

  @media all and (max-width: 768px) {
    margin: 10px auto 30px;
  }

  @media all and (max-width: 640px) {
    font-size: 11px;
    width: 90%;
  }

  @media all and (max-width: 480px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    width: 100%;
    margin: 10px auto 25px;
  }
`;

const Bold = styled.span`
  font-size: 14px;
  font-family: "Gordita-Regular";
  font-weight: 600;
  color: #fc8b62;
  @media all and (max-width: 1280px) {
    font-size: 12px;
    letter-spacing: 0.2px;
  }

  @media all and (max-width: 980px) {
    width: 70%;
    margin: 30px auto;
  }
`;

const ButtonContainer = styled(Link)`
  color: #fff;
  background: #f28a61;
  padding: 15px 65px;
  border-radius: 8px;
  font-size: 13px;
  cursor: pointer;
  font-family: "Gordita-Medium";
  display: inline-block;
  @media all and (max-width: 1280px) {
    padding: 12px 50px;
    border-radius: 7px;
  }
  @media all and (max-width: 1080px) {
    padding: 12px 40px;
    border-radius: 7px;
    font-size: 12px;
  }
  @media all and (max-width: 768px) {
    font-size: 12px;
    padding: 12px 35px;
    border-radius: 5px;
  }

  @media all and (max-width: 640px) {
    padding: 12px 30px;
    width: 100%;
  }
`;

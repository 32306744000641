import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router";
import { Context } from "../../Contexts/Store";
import LandingPage from "../../screens/LandingPage";
import OtpPage from "../../screens/OtpPage";
import SignUpPage from "../../screens/SignUpPage";
import AppRouter from "./AppRouter";

function AuthRouter() {
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const phoneNumber = user_data?.phone;

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<AppRouter />} />
      <Route path="/signin" element={<SignUpPage />} />
      <Route
        path="/otp"
        element={!phoneNumber ? <Navigate to="/signin" /> : <OtpPage />}
      />
    </Routes>
  );
}

export default AuthRouter;

import { useContext, useState } from "react";
import * as React from "react";

//packages
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

//imports
import { dettConfig } from "../../axiosConfig";
import { Context } from "../Contexts/Store";
import { formatDate, getYYMMDD } from "../helpers/functions";
import HeadShake from "react-reveal/HeadShake";
import DobCalendar from "../modal/DobCalendar";
import Buttons from "../general/Buttons";
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";

export default function Accounts() {
  const [isShow, setShow] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [classDate, setClassDate] = useState(false);

  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [value, onChange] = useState();

  const [isActive, setActive] = useState("");
  const [validateError, setValidateError] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const access_token = user_data.access_token;
  const navigate = useNavigate();

  const handleCalendarlModal = () => {
    setShow(!isShow);
  };

  //enter key function
  document.onkeyup = enter;
  function enter(e) {
    if (e.which == 13) {
      !validateError && name && accountVerification();
    }
  }

  //prevent numeric values
  const preventNumbersinName = (event) => {
    let value = event.target.value;
    value = value.replace(/[^A-Za-z, " "]/gi, "");
    setName(value);
  };
  //name and dob register api
  const accountVerification = () => {
    !validateError && setLoading(true);
    !validateError &&
      dettConfig
        .post(
          "api/v1/accounts/set/dob-and-name/",
          {
            dob: date ? getYYMMDD(date) : null,
            name: name,
          },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((response) => {
          const { StatusCode, data } = response.data;
          if (StatusCode === 6000) {
            navigate("/success");
            setClassDate(false);
            setLoading(false);
            dispatch({
              type: "UPDATE_USER_DETAILS",
              user_details: {
                name: name,
                dob: getYYMMDD(date),
              },
            });
          } else if (StatusCode === 6001) {
            setLoading(false);
            setError(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
  };
  const validatePrevoiusDate = debounce((e) => {
    checkDate(e);
  }, 1000);

  const checkDate = (e) => {
    var selectedDate = document.getElementById("datepicker").value;
    var now = new Date();
    var today = now?.toLocaleDateString("en-US");
    if (formatDate(selectedDate) >= formatDate(today)) {
      setValidateError("You can't enter a future date");
    } else {
      setValidateError("");
    }
  };

  return (
    <MainContainer className="mobile">
      <SubContainer className="wrapper">
        <Container>
          <LeftContainer>
            <Paragraph>
              Setup your <Span>account</Span> your <Br />
              <Span>gifts are waiting</Span>
            </Paragraph>
            <CoverContainer
              onClick={() => setActive("name")}
              className={isActive === "name" && "active"}
            >
              <Title
                onClick={() => setActive("name")}
                className={isActive === "name" && "active"}
              >
                Enter Name
              </Title>
              <Bottom>
                <Icon>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Profile.svg"
                    alt="profile"
                  />
                </Icon>
                <input
                  type="text"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={(e) => preventNumbersinName(e)}
                />
              </Bottom>
              <Error>{isError && !name && "This field is required"}</Error>
            </CoverContainer>
            <CoverContainer
              onClick={() => setActive("occation date")}
              className={isActive === "occation date" && "active"}
            >
              <Title
                onClick={() => setActive("occation date")}
                className={isActive === "occation date" && "active"}
              >
                Date of birth
              </Title>
              <Bottom
                onClick={() => {
                  setClassDate(true);
                }}
                className={classDate && "active"}
              >
                <Icon
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Calendar.svg"
                    alt="profile"
                  />
                </Icon>
                <input
                  type="date"
                  value={date}
                  id="datepicker"
                  autoComplete="off"
                  max="9999-12-31"
                  className={classDate && "active"}
                  onClick={(e) => e.preventDefault()}
                  onChange={(e) => {
                    setDate(e.target.value);
                    setClassDate(true);
                    validatePrevoiusDate(e);
                  }}
                />
                <DobCalendar
                  setShow={setShow}
                  setDate={setDate}
                  date={date}
                  setClassDate={setClassDate}
                  value={value}
                  onChange={onChange}
                  isShow={isShow}
                  handleCalendarlModal={handleCalendarlModal}
                />
              </Bottom>
              <Error>
                {isError && !date && "This field is required"}
                {validateError}
              </Error>
            </CoverContainer>

            <OptTag>
              {/* <OtpButton onClick={() => accountVerification()}>
                Continue
              </OtpButton> */}
              <Buttons
                onClickFunction={accountVerification}
                title={"Continue"}
                isLoading={isLoading}
                tag={name}
              />
            </OptTag>
          </LeftContainer>

          <RightContainer>
            <GifImage>
              <Image src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/accountimage.png" />
            </GifImage>
          </RightContainer>
        </Container>
      </SubContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/bgbar.png")
    no-repeat;
  background-size: contain;
  background-position: left top 15px;

  @media all and (max-width: 1280px) {
    background-position: left -75px top 15px;
    background-size: 48%;
  }

  @media all and (max-width: 1080px) {
    background-position: left -178px top 30px;
    background-size: 65%;
  }

  @media all and (max-width: 980px) {
    background-position: left -150px top 15px;
  }

  @media all and (max-width: 768px) {
    background-position: left -140px top 25px;
    background-size: 65%;
  }

  @media all and (max-width: 640px) {
    background-position: left -115px top 38px;
    background-size: 68%;
  }

  @media all and (max-width: 480px) {
    background: unset;
  }
`;

const SubContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 70%;
  height: 63vh;
  padding: 50px 50px 50px 100px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 5px 1px 8px #dddd;

  @media all and (max-width: 1280px) {
    width: 65%;
  }

  @media all and (max-width: 1080px) {
    padding: 50px 35px 50px 62px;
  }

  @media all and (max-width: 980px) {
    height: 70vh;
    width: 75%;
    padding: 50px 40px 50px 50px;
    flex-direction: column-reverse;
  }

  @media all and (max-width: 640px) {
    /* flex-wrap: wrap-reverse; */
    padding: 50px 40px 25px 50px;
  }
  @media all and (max-width: 480px) {
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
  }
`;

const Br = styled.br`
  @media all and (max-width: 980px) {
    display: none;
  }
  @media all and (max-width: 768px) {
    display: block;
  }
  @media all and (max-width: 640px) {
    /* display: none; */
  }
`;
const CoverContainer = styled.div`
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  position: relative;
  margin-top: 22px;
  &.active {
    border-bottom: 1px solid #fc8b62;
  }
`;

const LeftContainer = styled.div`
  width: 48%;
  text-align: center;
  @media all and (max-width: 980px) {
    width: 80%;
  }
`;

const Paragraph = styled.p`
  width: 100%;
  /* margin: 0 auto; */
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Gordita-Regular";
  text-align: left;

  @media all and (max-width: 1280px) {
    width: 80%;
  }

  @media all and (max-width: 1080px) {
    width: 90%;
  }

  @media all and (max-width: 980px) {
    width: 100%;
    font-size: 16px;
  }

  @media all and (max-width: 768px) {
    font-size: 16px;
  }
  @media all and (max-width: 640px) {
    font-size: 16px;
  }
  @media all and (max-width: 480px) {
    text-align: center;
    margin-bottom: 50px;
    font-size: 22px;
  }
  @media all and (max-width: 360px) {
    font-size: 20px;
  }
`;

const Span = styled.span`
  color: #fc8b62;
  font-size: 18px;
  font-weight: 600;
  font-family: "Gordita-Regular";

  @media all and (max-width: 768px) {
    font-size: 16px;
  }
  @media all and (max-width: 980px) {
    margin: 0px 3px;
  }
  @media all and (max-width: 480px) {
    font-size: 22px;
  }
  @media all and (max-width: 360px) {
    font-size: 20px;
  }
`;

const Error = styled.div`
  color: #e93a22;
  margin: 10px 0;
  text-align: right;
  height: 20px;
  position: absolute;
  right: 0;
  font-size: 12px;
`;

const Icon = styled.div`
  width: 12px;
  cursor: pointer;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 980px) {
    width: 15px;
  }
`;

const OptTag = styled.div`
  text-align: left;
  margin-top: 50px;
`;

const OtpButton = styled.div`
  width: 100%;
  display: inline-block;
  color: #fff;
  border-radius: 6px;
  background: #fc8b62;
  padding: 13px 65px;
  font-size: 13px;
  cursor: pointer;
  font-family: "Gordita-Medium";
  text-align: center;

  @media all and (max-width: 980px) {
    padding: 15px 40px;
    font-size: 12px;
  }

  @media all and (max-width: 768px) {
    padding: 12px 15px;
    font-size: 11px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;

const RightContainer = styled.div`
  width: 50%;
  /* margin: 0 auto; */
  @media all and (max-width: 980px) {
    /* display: none; */
    width: 57%;
  }
  @media all and (max-width: 768px) {
    width: 65%;
  }
  @media all and (max-width: 640px) {
    width: 78%;
  }
  @media all and (max-width: 540px) {
    width: 88%;
  }
  @media all and (max-width: 480px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

const PolicyDetails = styled.div``;

const GifImage = styled.div`
  width: 100%;

  @media all and (max-width: 980px) {
    width: 90%;
  }

  @media all and (max-width: 768px) {
    width: 95%;
  }
  @media all and (max-width: 480px) {
    margin: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  display: block;
`;
const Title = styled.label`
  color: #747474;
  font-size: 13px;
  text-align: left;
  font-family: "Gordita-Regular";
  &.active {
    color: #fc8b62;
  }
  @media all and (max-width: 980px) {
    font-size: 14px;
  }
`;
const Bottom = styled.div`
  margin: 5px 0;
  display: flex;

  ::-webkit-input-placeholder {
    color: transparent;
  }
  &.active {
    color: #191919 !important;
    ::after {
      content: "";
      width: 30px;
      height: 30px;
      background: #fff;
      position: absolute;
      right: 0;
      top: 15px;
    }
  }
  input {
    width: 100%;
    font-family: "Gordita-Regular";
    padding-left: 8px;
    color: #191919;
    font-weight: 400;
    ::placeholder {
      @media all and (max-width: 480px) {
        font-size: 15px;
      }
    }
    @media all and (max-width: 480px) {
      font-size: 15px;
    }
  }
  input[type="date"] {
    color: #9b9b9b;
    text-transform: uppercase;
    &.active {
      color: #191919 !important;
    }
  }

  @media all and (max-width: 980px) {
    margin-top: 10px;
  }
`;

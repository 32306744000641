import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function OccasionSuccess() {
  return (
    <MainContainer className="mobile">
      <Container>
        <Content>
          <ImageContainer>
            <img
              src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/success-occassion.png"
              alt="image"
            />
          </ImageContainer>
          <Text>
            Your occasion added <Br />
            <span>successfully!</span>
          </Text>
        </Content>
      </Container>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/bgbar.png")
    no-repeat;
  background-size: contain;
  background-position: left top 45px;

  @media all and (max-width: 1280px) {
    background-position: left -100px top 15px;
    background-size: 55%;
  }

  @media all and (max-width: 1080px) {
    background-position: left -200px top 15px;
    background-size: 65%;
  }

  @media all and (max-width: 980px) {
    background-position: left -150px top 85px;
  }

  @media all and (max-width: 768px) {
    background-position: left -140px top 128px;
    background-size: 65%;
  }
  @media all and (max-width: 640px) {
    background: none;
  }
`;

const Content = styled.div``;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 50%;
  height: 58vh;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 5px 1px 8px #dddd;

  @media all and (max-width: 1280px) {
    width: 75%;
  }

  @media all and (max-width: 980px) {
    height: 50vh;
    padding: 50px 40px 50px 50px;
  }
  @media all and (max-width: 480px) {
    box-shadow: none;
  }
`;
const ImageContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 980px) {
    width: 46%;
  }
`;
const Text = styled.h2`
  color: #4a4a4a;
  font-size: 18px;
  text-align: center;
  font-family: "Gordita-Medium";
  span {
    color: #fe9a76;
  }
  @media all and (max-width: 360px) {
    font-size: 14px;
  }
`;
const Br = styled.br`
  @media all and (max-width: 360px) {
    display: none;
  }
`;

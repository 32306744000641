import React, { useContext, useEffect, useRef, useState } from "react";

//packages
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
//imports
import { Context } from "../Contexts/Store";
import { debounce } from "lodash";
import SuccessModal from "../modal/SuccessModal";
import "react-calendar/dist/Calendar.css";
import ButtonLoader from "../general/loaders/ButtonLoader";
import CalendarModal from "../modal/CalendarModal";
import { formatDate, getYYMMDD } from "../helpers/functions";
import { dettConfig } from "../../axiosConfig";
import DropDownLoader from "../general/loaders/DropDownLoader";

function Gifting() {
  const [suggestion, setSuggestion] = useState(false);
  const [isShow, setShow] = useState(false);
  const [classDate, setClassDate] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isApiError, setApiError] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [isError, setError] = useState(false);
  const [occassionLoader, setOccassionLoader] = useState(true);
  const [relationLoader, setrelationLoader] = useState(true);
  const [suggestionsLoader, setSuggestionsLoader] = useState(true);

  const [name, setName] = useState();
  const [date, setDate] = useState("");
  const [validateError, setValidateError] = useState("");

  const [isActive, setActive] = useState("");
  const [relation, setRelation] = useState();
  const [product, setProduct] = useState();
  const [occasion, setOccasion] = useState();

  const [relationList, setRelationList] = useState([]);
  const [occasionList, setOccasionList] = useState([]);
  const [productList, setProductList] = useState([]);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  //enter key function
  document.onkeyup = enter;
  function enter(e) {
    e.preventDefault();
    if (e.which == 13) {
      !validateError && formSubmit(e);
      setSuggestion(false);
      setError(true);
    }
  }

  const {
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  // calendar handle function
  const handleCalendarlModal = () => {
    setShow(!isShow);
  };

  // Success handle function
  const handleSuccessModal = () => {
    setSubmit(!isSubmit);
  };

  //prevent numbers function
  const preventNumbersinName = (event) => {
    let value = event.target.value;
    value = value.replace(/[^A-Za-z, " "]/gi, "");
    setName(value);
  };
  const preventNumbersinRealtion = (event) => {
    let value = event.target.value;
    value = value.replace(/[^A-Za-z, " "]/gi, "");
    setRelation(value);
  };

  //outside-click function
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSuggestion(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  //   Relation Api Connection---------
  useEffect(() => {
    dettConfig
      .get("api/v1/accounts/relations/", {
        params: {
          q: relation,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data;
        if (StatusCode === 6000) {
          setRelationList(data.data);
          setrelationLoader(false);
        } else {
          setrelationLoader(false);
        }
      })
      .catch((error) => {
        setApiError(false);
      });
  }, [relation]);

  //   Occation Api Connection---------

  useEffect(() => {
    dettConfig
      .get("api/v1/bookings/occasions/", {
        params: {
          q: occasion,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data;
        if (StatusCode === 6000) {
          setOccasionList(data.data);
          setOccassionLoader(false);
        } else {
          setOccassionLoader(false);
        }
      })
      .catch((error) => {
        setApiError(false);
      });
  }, [occasion]);

  //   Gift Suggestion Api Connection---------
  useEffect(() => {
    dettConfig
      .get("api/v1/bookings/gift/suggestions/", {
        params: {
          q: product,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data;
        if (StatusCode === 6000) {
          setProductList(data.data);
          setSuggestionsLoader(false);
        } else {
          setSuggestionsLoader(false);
        }
      })
      .catch((error) => {
        setApiError(false);
      });
  }, [product]);

  // Form submit Api ------------
  const formSubmit = () => {
    setApiError(true);
    dettConfig
      .post(
        "api/v1/bookings/set/booking/",
        {
          occasion_date: getYYMMDD(date),
          relation_name: name,
          occasion: occasion,
          gift_suggestion: product,
          relationship: relation,
          is_recurring: isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        const { StatusCode, data } = response.data;
        if (StatusCode === 6000) {
          setSubmit(true);
          setApiError(false);
        } else if (StatusCode === 6001) {
          setApiError(false);
        }
      })
      .catch((error) => {
        setApiError(false);
      });
  };
  //   debounce;
  const handleOccasion = debounce((text) => {
    setOccasion(text);
  }, 1000);
  const handleRelation = debounce((text) => {
    setRelation(text);
  }, 1000);
  const handleSuggestion = debounce((text) => {
    setProduct(text);
  }, 1000);

  const validatePrevoiusDate = debounce((e) => {
    checkDate(e);
  }, 1000);
  const checkDate = (e) => {
    var selectedDate = document.getElementById("datepicker").value;
    var now = new Date();
    var today = now?.toLocaleDateString("en-US");
    if (formatDate(selectedDate) <= formatDate(today)) {
      setValidateError("Please enter a future date");
    } else {
      setValidateError("");
    }
  };
  // //------------------------------------------------------------------------------------------------
  // let sortedNames = relationList.sort();
  // console.log(sortedNames, "---------------");
  // //reference
  // let input = document.getElementById("input");
  // //Execute function on keyup
  // input?.addEventListener("keyup", (e) => {
  //   //loop through above array
  //   //Initially remove all elements ( so if user erases a letter or adds new letter then clean previous outputs)
  //   removeElements();
  //   for (let i of sortedNames) {
  //     //convert input to lowercase and compare with each string
  //     if (
  //       i.toString().toLowerCase().startsWith(input.value.toLowerCase()) &&
  //       input.value != ""
  //     ) {
  //       //create li element
  //       let listItem = "";
  //       //One common class name
  //       listItem.classList.add("list-items");
  //       listItem.style.cursor = "pointer";
  //       listItem.setAttribute("onclick", "displayNames('" + i + "')");
  //       //Display matched part in bold
  //       let word = "<b>" + i.toString().substr(0, input.value.length) + "</b>";
  //       word += i.toString().substr(input.value.length);
  //       //display the value in array
  //       listItem.innerHTML = word;
  //       console.log(listItem, "-=-==--=-=");
  //       document.querySelector(".list").appendChild(listItem);
  //     }
  //   }
  // });
  // function displayNames(value) {
  //   input.value = value;
  //   removeElements();
  // }
  // function removeElements() {
  //   //clear all the item
  //   let items = document.querySelectorAll(".list-items");
  //   items.forEach((item) => {
  //     item.remove();
  //   });
  // }
  // //------------------------------------------------------------------------------------------------
  return (
    <GiftingForm className="mobile">
      <FormBox>
        <SubBox>
          <Top>
            <Heading>Let’s wrap love with lot’s</Heading>
            <Headingtwo>of Surprises</Headingtwo>
          </Top>
          <DetailsForm autoComplete="off">
            <Container
              onClick={() => setActive("name")}
              className={isActive === "name" && "active"}
            >
              <Title>Name</Title>
              <Bottom>
                <Icon>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Profile.svg"
                    alt="profile"
                  />
                </Icon>
                <input
                  type="text"
                  placeholder="Whom do you want to surprise?"
                  value={name}
                  onChange={(e) => preventNumbersinName(e)}
                />
              </Bottom>
              <Error>{isError && !name && "This field is required"}</Error>
            </Container>
            <Container
              onClick={() => {
                setClassDate(true);
                setActive("occation date");
              }}
              className={isActive === "occation date" && "active"}
            >
              <Title>Occasion date</Title>
              <Bottom className="active-block">
                <Icon onClick={() => handleCalendarlModal()}>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Calendar.svg"
                    alt="profile"
                  />
                </Icon>
                <input
                  type="date"
                  id="datepicker"
                  value={date}
                  onClick={(e) => e.preventDefault()}
                  max="9999-12-31"
                  // min={new Date()}
                  className={classDate && "active"}
                  onChange={(e) => {
                    setDate(e.target.value);
                    setClassDate(true);
                    validatePrevoiusDate(e);
                  }}
                />
                <CalendarModal
                  setClassDate={setClassDate}
                  setShow={setShow}
                  setDate={setDate}
                  date={date}
                  isShow={isShow}
                  handleCalendarlModal={handleCalendarlModal}
                />
              </Bottom>
              <Error>
                {isError && !date && "This field is required"}
                {validateError}{" "}
              </Error>
            </Container>
            {/* comment---------------------------------------------------------------------------------------------------------- */}

            <Container
              onClick={() => {
                setActive("relation");
                // renderRelations()
              }}
              className={isActive === "relation" && "active"}
            >
              <Title>Relation</Title>
              <Bottom onClick={() => setSuggestion(true)}>
                <Icon>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Userprofile.svg"
                    alt="2 user"
                  />
                </Icon>
                <input
                  type="text"
                  // id="input"
                  placeholder="Enter your relation"
                  value={!suggestion ? relation : null}
                  onChange={(e) => {
                    handleRelation(e.target.value);
                    setActive("relation");
                    // displayNames(e);
                  }}
                />
              </Bottom>
              {relationList.length > 0 &&
                suggestion &&
                isActive === "relation" && (
                  <DropBox type={relationList} ref={wrapperRef}>
                    <Group>
                      {relationLoader ? (
                        <DropDownLoader />
                      ) : (
                        relationList.map((item) => (
                          <Relation
                            onClick={() => {
                              setRelation(item.title);
                              setSuggestion(false);
                            }}
                          >
                            {item.title}{" "}
                          </Relation>
                        ))
                      )}
                    </Group>
                  </DropBox>
                )}
              <Error>{isError && !relation && "This field is required"}</Error>
            </Container>
            {/* comment---------------------------------------------------------------------------------------------------------- */}

            <Container
              onClick={() => {
                setActive("suggestion");
                // renderSuggestions();
              }}
              className={isActive === "suggestion" && "active"}
            >
              <Title>Gift Suggestion</Title>
              <Bottom onClick={() => setSuggestion(true)}>
                <Icon>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Profile.svg"
                    alt="profile"
                  />
                </Icon>
                <input
                  type="text"
                  placeholder="Enter your gift suggestions here"
                  value={!suggestion ? product : null}
                  onChange={(e) => {
                    handleSuggestion(e.target.value);
                    setActive("suggestion");
                  }}
                />
              </Bottom>
              {productList.length > 0 &&
                suggestion &&
                isActive === "suggestion" && (
                  <DropBox type={productList} ref={wrapperRef}>
                    <Group>
                      {suggestionsLoader ? (
                        <DropDownLoader />
                      ) : (
                        productList.map((item) => (
                          <Relation
                            onClick={() => {
                              setProduct(item.title);
                              setSuggestion(false);
                            }}
                          >
                            {item.title}{" "}
                          </Relation>
                        ))
                      )}
                    </Group>
                  </DropBox>
                )}
            </Container>
            <Container
              onClick={() => {
                setActive("occation");
                // renderOccassions()
              }}
              className={isActive === "occation" && "active"}
            >
              <Title>Occasion</Title>
              <Bottom onClick={() => setSuggestion(true)}>
                <Icon>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/datepick.svg"
                    alt="date"
                  />
                </Icon>
                <input
                  type="text"
                  placeholder="Enter your occasion"
                  value={!suggestion ? occasion : null}
                  onChange={(e) => {
                    handleOccasion(e.target.value);
                    setActive("occation");
                  }}
                />
              </Bottom>
              {occasionList.length > 0 &&
                suggestion &&
                isActive === "occation" && (
                  <DropBox type={occasionList} ref={wrapperRef}>
                    <Group>
                      {occassionLoader ? (
                        <DropDownLoader />
                      ) : (
                        occasionList.map((item) => (
                          <Relation
                            onClick={() => {
                              setOccasion(item.title);
                              setSuggestion(false);
                            }}
                          >
                            {item.title}{" "}
                          </Relation>
                        ))
                      )}
                    </Group>
                  </DropBox>
                )}
              <Error>{isError && !occasion && "This field is required"}</Error>
            </Container>
            <TickBox>
              <Checkbox onClick={handleOnChange}>
                {isChecked ? (
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Tick.svg"
                    alt=""
                  />
                ) : (
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/checkbox.svg"
                    alt=""
                  />
                )}
              </Checkbox>
              <Question>Is your occasion recurring?</Question>
            </TickBox>
          </DetailsForm>
          <Box>
            <Botton
              onClick={(e) => {
                e.preventDefault();
                setError(true);
                !validateError && formSubmit();
              }}
            >
              {isApiError ? <ButtonLoader /> : "Submit"}
            </Botton>
            <SuccessModal isSubmit={isSubmit} />
          </Box>
        </SubBox>
      </FormBox>
    </GiftingForm>
  );
}

const GiftingForm = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/bgbar.png")
    no-repeat;
  background-size: contain;
  background-position: left top 15px;
  margin: 0px auto;

  @media (max-width: 1080px) {
    background-size: 40%;
    background-position: left top 0px;
  }
  @media (max-width: 480px) {
    background: none;
    height: 100vh;
  }
`;
const FormBox = styled.div`
  background: #fff;
  width: 58%;
  height: 68vh;
  padding: 30px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 5px 1px 8px #dddd;

  @media (max-width: 1280px) {
    width: 63%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1080px) {
    width: 70%;
    margin: 0 auto;
  }
  @media (max-width: 980px) {
    width: 85%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    height: 75vh;
  }
  @media (max-width: 640px) {
    width: 80%;
    height: 100vh;
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const SubBox = styled.div`
  background: #fff;
  width: 90%;
  padding: 25px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    padding: 10px;
  }
  @media (max-width: 1080px) {
    width: 100%;
  }
`;
const Top = styled.div`
  text-align: center;
  @media (max-width: 1080px) {
    margin-top: 5px;
  }
`;
const Heading = styled.h3`
  font-size: 18px;
  font-weight: 500;
  font-family: "Gordita-Medium";
  margin-bottom: 8px;
  color: #4a4a4a;
`;
const Headingtwo = styled.h4`
  color: #fc8b62;
  font-size: 18px;
  font-weight: 600;
  font-family: "Gordita-Regular";
`;
const DetailsForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 640px) {
    width: 85%;
    margin: 0 auto;
  }
`;
const Container = styled.div`
  border-bottom: 1px solid #d4d4d4;
  width: 42%;
  position: relative;
  margin-top: 22px;
  &.active {
    border-bottom: 1px solid #fc8b62;
  }

  @media (max-width: 640px) {
    width: 300px;
  }
`;
const Title = styled.label`
  color: #747474;
  font-size: 13px;
  font-family: "Gordita-Regular";
`;
const Icon = styled.div`
  width: 16px;
  height: 24px;
  cursor: pointer;
  margin: 10px 5px 0px 0px;
  @media (max-width: 640px) {
    width: 14px;
    margin: 10px 5px -3px 0px;
  }
  img {
    width: 100%;
    display: block;
  }
`;
const Bottom = styled.div`
  margin-top: 5px;
  display: flex;
  &.active-block {
    ::after {
      content: "";
      width: 30px;
      height: 30px;
      background: #fff;
      position: absolute;
      right: 0;
      top: 26px;
    }
  }
  input[type="date"] {
    color: #d4d4d4;
    text-transform: uppercase;
    &.active {
      color: #191919;
    }
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }
  input {
    width: 100%;
    font-family: "Gordita-Regular";
    padding-left: 4px;
    font-weight: 400;
    color: #191919;
    ::placeholder {
      color: #d4d4d4;
      @media (max-width: 768px) {
        font-size: 11px;
      }
    }
  }
`;
const Botton = styled.button`
  width: 42%;
  height: 44px;
  background: #fc8c64;
  border-radius: 6px;
  margin-top: 25px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Gordita-Regular";
  cursor: pointer;
  @media (max-width: 640px) {
    width: 320px;
  }
`;
const DropBox = styled.div`
  width: 250px;
  height: 176px;
  position: absolute;
  top: 58px;
  z-index: 100;
  background: #fffcf7;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 20px;
  gap: 10px;
  justify-content: ${({ type }) => (type.length <= 0 ? "center" : "")};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background: #747474;
  }
`;
const Group = styled.ul`
  width: 100%;
`;

const Relation = styled.li`
  padding: 8px;
  font-family: "Gordita-medium";
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: #ffebe4;
    display: inline-block;
    border-radius: 5px;
  }
`;

const Error = styled.span`
  color: red;
  font-size: 10px;
  font-family: "Gordita-regular";
  position: absolute;
  margin-top: 5px;
`;

const TickBox = styled.div`
  width: 42%;
  margin-top: 56px;
  display: flex;
  height: 20px;
  position: relative;
  @media (max-width: 640px) {
    width: 250px;
  }
  input {
    width: 30px;
    cursor: pointer;
  }
  @media (max-width: 640px) {
    margin-top: 26px;
  }
`;

const Checkbox = styled.div`
  width: 18px;
  cursor: pointer;
  margin-right: 2%;
  img {
    width: 100%;
    display: block;
  }
`;

const Question = styled.span`
  font-size: 12px;
  color: #747474;
  font-family: "Gordita-regular";
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1080px) {
  }
  @media (max-width: 640px) {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }
`;

export default Gifting;

import React, { useEffect, useRef, useState } from "react";
//package
import styled from "styled-components";
import $ from "jquery";
import Calendar from "react-calendar";
import { formatDate } from "../helpers/functions";

function CalendarModal({ handleCalendarlModal,date, setShow, isShow, setDate,setClassDate }) {
  const [value, onChange] = useState();

  const selectedDate = value?.toLocaleDateString("en-US");
  useEffect(() => {
    if (isShow) {
      $("html").addClass("modal-enabled");
    } else {
      $("html").removeClass("modal-enabled");
    }
    // setDate(selectedDate);
  }, [isShow]);
  //----------OutsideClick---------------------------------------------------
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  useEffect(() => {
    selectedDate && setShow(false);
    selectedDate && setClassDate(true);
    selectedDate && setDate(formatDate(selectedDate))

  }, [selectedDate]);
  return (
    <MainContainer>
      {isShow ? <Overlay onClick={() => handleCalendarlModal()}></Overlay> : ""}
      <BackContainer style={{ transform: isShow && "scale(1,1)" }}>
        <Modal type="textarea">
          <Calendar onChange={onChange} value={value}  minDate={new Date()} />
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default CalendarModal;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  /* max-width: 650px; */
  margin: 0 auto;
  right: 0;
  /* height: 100vh; */
  z-index: 1000;
  left: 0;
  top: 50%;
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const MainContainer = styled.div``;
const Modal = styled.div`
  /* width: 90%;
    max-width: 490px; */
  padding: 20px 30px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  background: #fff;
  @media all and (max-width: 1280px) {
    width: 520px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 500px;
  }
  @media all and (max-width: 768px) {
    width: 480px;
  }
  @media all and (max-width: 640px) {
    width: 400px;
  }
  @media all and (max-width: 480px) {
    width: 340px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;

import React, { useContext } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { Context } from "../../Contexts/Store";

const PrivateRoute = ({ children }) => {
  const {
    state: { user_data },
  } = useContext(Context);

  const is_exists = user_data.is_exists;
  const location = useLocation();

  return is_exists ? (
    children
  ) : (
    <Navigate
      replace
      to={location.pathname ? `/?next=${location.pathname}` : "/"}
    />
  );
};

export default PrivateRoute;

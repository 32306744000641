import React from "react";
import styled from "styled-components";
import ButtonLoader from "./loaders/ButtonLoader";

function Buttons({ tag, title, onClickFunction, isLoading }) {
  return (
    <OtpButton type={tag} onClick={(e) => tag && onClickFunction(e)}>
      {isLoading ? <ButtonLoader /> : title}
    </OtpButton>
  );
}

export default Buttons;

const OtpButton = styled.div`
  width: 100%;
  height: 50px;
  display: inline-block;
  /* cursor: pointer; */
  cursor: ${({ type }) => (type ? "pointer" : "not-allowed")};
  border-radius: 6px;
  background: #fc8b62;
  color: #fff;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gordita-Medium";
  text-align: center;

  @media all and (max-width: 1160px) {
    font-size: 12px;
    border-radius: 5px;
  }
  @media all and (max-width: 980px) {
    padding: 15px 40px;
    font-size: 11px;
  }

  @media all and (max-width: 768px) {
    padding: 12px 15px;
    font-size: 11px;
    height: 43px;
  }
  @media all and (max-width: 640px){
    margin-bottom: 15px;
  }
  @media all and (max-width: 480px) {
    border-radius: 25px;
    padding: 14px 15px;
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 12px;
  }
`;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function SuccessPage() {
  return (
    <MainContainer className="mobile">
      <SubContainer className="wrapper">
        <Container>
          <Content>
            <ImageContainer>
              <img
                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/success.png"
                alt="successimage"
              />
            </ImageContainer>
            <Title>Registered successfully</Title>
            <Description>
              You have been registered successfully
              <br /> <span>start gifting</span> with us!
            </Description>
            <Button to="/gifting">Start gifting</Button>
          </Content>
        </Container>
      </SubContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/bgbar.png")
    no-repeat;
  background-size: contain;
  background-position: left top 30px;

  @media all and (max-width: 1280px) {
    background-position: left -100px top 54px;
    background-size: 55%;
  }

  @media all and (max-width: 1080px) {
    background-position: left -154px top 64px;
    background-size: 65%;
  }

  @media all and (max-width: 980px) {
    background-position: left -150px top 95px;
  }

  @media all and (max-width: 768px) {
    background-position: left -91px top 100px;
    background-size: 65%;
  }
  @media all and (max-width: 480px) {
    background: unset;
  }
`;

const SubContainer = styled.div``;
const ImageContainer = styled.div`
  width: 68%;
  margin: 0 auto;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 980px) {
    width: 64%;
  }
`;
const Title = styled.div`
  color: #4a4a4a;
  font-family: "Gordita-Medium";
  margin: 20px 0;
  @media all and (max-width: 980px) {
    font-size: 20px;
  }
`;
const Button = styled(Link)`
  background: #fc8b62;
  padding: 14px 42px;
  display: block;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  font-family: "Gordita-Regular";
  cursor: pointer;
  @media all and (max-width: 980px) {
    font-size: 15px;
  }
`;
const Description = styled.div`
  color: #747474;
  font-family: "Gordita-Regular";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
  span {
    color: #ff7e4f;
  }
  @media all and (max-width: 980px) {
    font-size: 15px;
  }
`;
const Content = styled.div`
  width: 40%;
  margin: 0 auto;
  text-align: center;
  @media all and (max-width: 980px) {
    width: 80%;
  }
  @media all and (max-width: 768px) {
    width: 86%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 65%;
  height: 58vh;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 5px 1px 8px #dddd;

  @media all and (max-width: 1280px) {
    width: 75%;
  }

  @media all and (max-width: 980px) {
    height: 58vh;
    padding: 50px 40px 50px 50px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    box-shadow: unset;
    padding: 0 20px;
  }
`;

const Reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER_DATA":
      const user_data = { ...state.user_data, ...action.user_data };
      localStorage.setItem("user_data", JSON.stringify(user_data));
      return {
        ...state,
        user_data: user_data,
      };
    case "UPDATE_USER_DETAILS":
      const user_details = { ...state.user_details, ...action.user_details };
      localStorage.setItem("user_details", JSON.stringify(user_details));
      return {
        ...state,
        user_details: user_details,
      };
    default:
      return state;
  }
};

export default Reducer;
